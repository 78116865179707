<script>
  import { page, Link } from '@inertiajs/svelte'
  import { onMount } from 'svelte'

  import Cart from '@/components/cart/Cart.svelte'
  import BurgerMenu from '@/components/common/BurgerMenu.svelte'
  import { cart, syncCart } from '@/store'

  let { children } = $props()
  const { cart: propCart } = $page.props

  let open = $state(false)
  let cartCount = $derived($cart.length)

  onMount(() => {
    syncCart(propCart.items)
  })

  function toggleMenu() {
    open = !open
  }
</script>

<header
  class="rounded-3xl bg-white my-3 p-4 lg:px-0 lg:rounded-full lg:bg-grey container mx-auto"
>
  <div class="flex justify-between items-center container mx-auto">
    <h1 class="text-2xl lg:text-3xl font-bold text-center">Kitsune Store</h1>
    <nav class="hidden lg:flex gap-10 text-center">
      <ul class="flex text-xl gap-6">
        <li><Link href="/" class="hover:underline">Home</Link></li>
        <li><a href="/about" class="hover:underline">About</a></li>
        <li><a href="/contact" class="hover:underline">Contact</a></li>
      </ul>
    </nav>

    <div class="flex gap-2 justify-center">
      <Link href="/cart">
        <Cart count={cartCount} />
      </Link>
      <button aria-label="menu" class="lg:hidden" onclick={toggleMenu}>
        <BurgerMenu />
      </button>
    </div>

    <!-- {#if locale === 'en'}
      <a href="/?locale=th">th</a>
    {:else if locale === 'th'}
      <a href="/?locale=en">en</a>
    {/if} -->
  </div>
  {#if open}
    <nav class="lg:hidden flex gap-10 justify-center pt-4">
      <ul class="flex text-xl gap-6">
        <li><Link href="/" class="hover:underline">Home</Link></li>
        <li><a href="/about" class="hover:underline">About</a></li>
        <li><a href="/contact" class="hover:underline">Contact</a></li>
      </ul>
    </nav>
  {/if}
</header>

<main class="py-10 container mx-auto">
  {@render children()}
</main>

<footer class="bg-gray-200 text-center py-6">
  <div class="container mx-auto px-4">
    <p class="text-sm text-gray-600">
      &copy; 2025 Kitsune Store. All rights reserved.
    </p>
  </div>
</footer>

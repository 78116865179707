<script>
  import { post } from '@rails/request.js'

  import Trash from '@/components/common/Trash.svelte'
  import { currencyFormatter } from '@/helpers/currency'
  import { syncCart } from '@/store'

  export let item

  async function handleUpdateQuantity(item, newQuantity) {
    if (newQuantity >= 0) {
      const response = await post('/cart/update_item', {
        body: JSON.stringify({
          item: { code: item.code, quantity: newQuantity }
        }),
        responseKind: 'json'
      })
      if (response.ok) {
        const data = await response.json
        syncCart(data.items)
      }
    }
  }

  async function handleRemoveItem(item) {
    const response = await post('/cart/remove', {
      body: JSON.stringify({ item: { code: item.code } }),
      responseKind: 'json'
    })
    if (response.ok) {
      const data = await response.json
      syncCart(data.items)
    }
  }
</script>

<article
  class="flex flex-col first:border-t border-b border-x border-black-secondary p-4 gap-4"
>
  <div class="flex gap-4">
    <figure class="w-52">
      <img
        alt="logo"
        loading="lazy"
        src="https://cdn.prod.website-files.com/655375faf5e7806a315ace40/6761776411b534b46db181fb_Brutalism.png"
        sizes="(max-width: 767px) 45vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 392px"
        srcset="https://cdn.prod.website-files.com/655375faf5e7806a315ace40/6761776411b534b46db181fb_Brutalism-p-1080.png 1080w, https://cdn.prod.website-files.com/655375faf5e7806a315ace40/6761776411b534b46db181fb_Brutalism.png 1640w"
        class="object-cover"
      />
    </figure>
    <div class="w-full">
      <div class="flex items-center justify-between w-full mb-4">
        <h5 class="font-bold text-xl">{item.code}</h5>
        <button
          aria-label="delete"
          class="rounded-full flex items-center justify-center focus-within:outline-red-500"
          onclick={() => handleRemoveItem(item)}
        >
          <Trash />
        </button>
      </div>
      <p>{item.description}</p>
    </div>
  </div>
  <div class="flex justify-between items-center">
    <div class="flex items-center h-full">
      <button
        aria-label="remove"
        class="group rounded-l-xl w-10 h-10 border border-gray-200 flex items-center justify-center shadow-sm shadow-transparent transition-all duration-500 hover:bg-gray-50 hover:border-gray-300 hover:shadow-gray-300 focus-within:outline-gray-300"
        onclick={() => handleUpdateQuantity(item, item.quantity - 1)}
        disabled={item.quantity <= 1}
      >
        <svg
          class="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M16.5 11H5.5"
            stroke=""
            stroke-width="1.6"
            stroke-linecap="round"
          />
          <path
            d="M16.5 11H5.5"
            stroke=""
            stroke-opacity="0.2"
            stroke-width="1.6"
            stroke-linecap="round"
          />
          <path
            d="M16.5 11H5.5"
            stroke=""
            stroke-opacity="0.2"
            stroke-width="1.6"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <input
        type="text"
        class="border-y border-gray-200 w-10 h-10 outline-none text-gray-900 text-lg text-center bg-white"
        value={item.quantity}
        placeholder="1"
        onchange={(e) => handleUpdateQuantity(item, e.target.value)}
      />
      <button
        aria-label="add"
        class="group rounded-r-xl w-10 h-10 border border-gray-200 flex items-center justify-center shadow-sm shadow-transparent transition-all duration-500 hover:bg-gray-50 hover:border-gray-300 hover:shadow-gray-300 focus-within:outline-gray-300"
        onclick={() => handleUpdateQuantity(item, item.quantity + 1)}
      >
        <svg
          class="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M11 5.5V16.5M16.5 11H5.5"
            stroke=""
            stroke-width="1.6"
            stroke-linecap="round"
          />
          <path
            d="M11 5.5V16.5M16.5 11H5.5"
            stroke=""
            stroke-opacity="0.2"
            stroke-width="1.6"
            stroke-linecap="round"
          />
          <path
            d="M11 5.5V16.5M16.5 11H5.5"
            stroke=""
            stroke-opacity="0.2"
            stroke-width="1.6"
            stroke-linecap="round"
          />
        </svg>
      </button>
    </div>
    <h6 class="text-black-secondary font-bold text-2xl leading-9 text-right">
      {currencyFormatter.format(item.quantity * item.price)} ฿
    </h6>
  </div>
</article>

<script>
  export let count
</script>

<div class="group p-2 transition-all duration-500 relative">
  {#if count && count > 0}
    <div class="absolute top-0 right-0">
      <p
        class="flex w-4 h-4 items-center justify-center rounded-full bg-red-500 p-2 text-xs text-white"
      >
        {count}
      </p>
    </div>
  {/if}
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    ><g
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      ><circle cx="9" cy="21" r="1" /><circle cx="20" cy="21" r="1" /><path
        d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
      /></g
    ></svg
  >
</div>

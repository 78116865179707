<script>
  import { router } from '@inertiajs/svelte'
  import { z, ZodError } from 'zod'

  // Props from controller
  let { errors } = $props()

  const schema = z.object({
    email: z.string().email(),
    password: z.string().trim()
  })

  let data = {
    email: null,
    password: null
  }

  let formErrors = $state({})

  function handleSubmit(e) {
    e.preventDefault()
    try {
      schema.parse(data)
      router.post('/session', data)
    } catch (err) {
      if (err instanceof ZodError) {
        formErrors = err.issues.reduce((acc, err) => {
          return { ...acc, [err.path]: err.message }
        }, {})
      }
    }
  }
</script>

<div class="w-full">
  <div class="w-full md:w-2/3 flex flex-col my-10 mx-auto items-center">
    <h1 class="text-3xl font-bold">Login</h1>
    <form class="flex flex-col gap-2 w-full" onsubmit={handleSubmit}>
      <label for="email">Email:</label>
      <input id="email" bind:value={data.email} required />
      {#if formErrors.email}
        <span class="text-red-400 font-bold">{formErrors.email}</span>
      {/if}

      <label for="password">Password:</label>
      <input
        id="password"
        type="password"
        bind:value={data.password}
        required
      />
      {#if formErrors.password}
        <span class="text-red-400 font-bold">{formErrors.password}</span>
      {/if}

      {#if errors}
        <span class="text-red-400 font-bold">{errors}</span>
      {/if}

      <button
        type="submit"
        class="rounded-lg border-2 border-black bg-green-400 text-white my-2 py-3"
      >
        Submit
      </button>
    </form>
  </div>
</div>

<script>
  import Layout from '@/components/Layout.svelte'
  import CartItem from '@/components/cart/CartItem.svelte'
  import { currencyFormatter } from '@/helpers/currency'
  import { cart } from '@/store'

  let items = $derived($cart?.length)
  let amount = $derived.by(() => {
    return $cart?.reduce((price, item) => {
      return price + item.price * item.quantity
    }, 0)
  })
</script>

<Layout>
  <div>
    <h2 class="text-2xl font-bold text-black-secondary">My Cart</h2>
    <hr />
    <div class="py-10">
      {#each $cart as item, index (item.id)}
        <CartItem {item} />
      {/each}
    </div>

    <h2 class="text-2xl font-bold text-black-secondary">Order summary</h2>
    <hr />
    <div class="flex justify-between py-10">
      <p>{items} items</p>
      <p>{currencyFormatter.format(amount)} ฿</p>
    </div>

    <button
      class="rounded-3xl px-4 py-2 bg-black-secondary text-white hover:scale-110 duration-500"
      onclick={() => handleAddToCart(product)}
    >
      Checkout
    </button>
  </div>
</Layout>

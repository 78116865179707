<script>
  import { post } from '@rails/request.js'

  import { syncCart } from '@/store'

  export let product

  async function handleAddToCart(item) {
    const response = await post('/cart/add', {
      body: JSON.stringify({ item: item }),
      responseKind: 'json'
    })
    if (response.ok) {
      const data = await response.json
      syncCart(data.items)
    }
  }
</script>

<article
  class="w-1/2 lg:w-1/3 border border-black-secondary bg-white grid grid-card"
>
  <figure class="border-b border-black-secondary">
    <img
      alt="logo"
      loading="lazy"
      src="https://cdn.prod.website-files.com/655375faf5e7806a315ace40/6761776411b534b46db181fb_Brutalism.png"
      sizes="(max-width: 767px) 45vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 392px"
      srcset="https://cdn.prod.website-files.com/655375faf5e7806a315ace40/6761776411b534b46db181fb_Brutalism-p-1080.png 1080w, https://cdn.prod.website-files.com/655375faf5e7806a315ace40/6761776411b534b46db181fb_Brutalism.png 1640w"
      class="object-cover"
    />
  </figure>
  <div class="border-b border-black-secondary p-4">
    <p class="text-lg">{product.name}</p>
    <p>{product.description}</p>
  </div>
  <div class="p-4 flex justify-between items-center">
    <p class="text-2xl font-bold">{product.price} ฿</p>
    <button
      class="rounded-3xl px-4 py-2 bg-black-secondary text-white hover:scale-110 duration-500"
      onclick={() => handleAddToCart(product)}
    >
      Add to cart
    </button>
  </div>
</article>

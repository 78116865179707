import { writable } from "svelte/store"

export const cart = writable([])

export function removeFromCart(code) {
  cart.update((items) => items.filter(i => i.code != code))
}

export function clearCart(){
  cart.set([])
}

export function syncCart(items) {
  cart.set(items)
}


export function updateItemQuantity(code, quantity) {
  cart.update((currentCart) => {
    return currentCart.map((item) =>
      item.code === code
        ? { ...item, quantity: Math.max(quantity, 0) } // Prevent negative quantities
        : item
    );
  });
}


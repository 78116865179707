<script>
  import Layout from '@/components/Layout.svelte'
  import ProductItem from '@/components/products/ProductItem.svelte'

  const { i18n: t, products } = $props()
</script>

<Layout>
  <div>
    <div class="flex gap-4">
      {#each products as product}
        <ProductItem {product} />
      {/each}
    </div>
  </div>
</Layout>
